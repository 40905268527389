import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../../../redux/modules/db';
import { selectCurrentFloorId } from '../../../redux/selectors/selectCurrentFloorId';

export const selectCurrentFloor = createSelector(
  dbBaseSelectors.floor.selectEntities,
  selectCurrentFloorId,
  (floors, selectedFloor) => floors[selectedFloor],
);
