import { PartialItem } from '@directus/sdk';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { dbBaseSelectors } from '../../app/redux/modules/db';
import { selectExhibitorsByFloorId } from '../../app/redux/selectors/selectExhibitorsByFloorId';
import { useAppSelector } from '../../app/redux/store';
import { Floor } from '../../app/types';
import Heading from '../../app/view/components/Heading';

interface Props {
  floor: PartialItem<Floor> | Floor['id'];
}

const Container = styled.div`
  width: auto;
  padding-bottom: 30px;
`;

const Exhibitors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.6rem;
`;

const Exhibitor = styled(Link)`
  width: 50%;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.black};
  line-height: 1.3em;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
`;

export default function FloorExhibitorsList({ floor }: Props) {
  const floorId = (typeof floor === 'string' ? floor : floor.id) || '';
  const currentFloor = useAppSelector(state => dbBaseSelectors.floor.selectById(state, floorId));
  const exhibitors = useAppSelector(state => selectExhibitorsByFloorId(state, floorId, true));

  return (
    <Container>
      <Heading level={ 2 } marginBottom="0">{ currentFloor?.name }</Heading>
      <Exhibitors>
        {
          exhibitors?.map(({ name, id }) => (<Exhibitor to={ `exhibitors/${id}` } key={ id }>{ name }</Exhibitor>))
        }
      </Exhibitors>
    </Container>
  );
}
