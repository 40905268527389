import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import SearchBox from '../Searchbox';
import { ReactComponent as AccessibleSvg } from './barrierefrei.svg';
import FooterNavigation from './FooterNavigation/FooterNavigation';
import ImageHeader from './ImageHeader';
import ScrollButtons from './ScrollButtons';
import { theme } from './theme';
import TopicNavigation from './TopicNavigation';

const Container = styled.div`
  background: #fff;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ a11y: boolean }>`
  position: relative;
  width: 100vw;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  padding-top: ${({ a11y }) => a11y ? '400px' : '0px'};
`;

const AccessibleIcon = styled(AccessibleSvg)<AccessibleIconProps>`
  position: fixed;
  right: 15px;
  top: 1170px;
  color: ${({ active, theme }) => active ? theme.palette.primary.main : '#cecece'};
`;

interface Props {
  className?: string;
}

interface AccessibleIconProps {
  active?: 'true';
}

export default function Layout({ children, className }: PropsWithChildren<Props>) {
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  const [a11y, setA11y] = useState(false);

  useLayoutEffect(() => {
    contentRef.current?.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ThemeProvider theme={ theme }>
      <Container className={ className } onContextMenu={ (e) => e.preventDefault() }>
        <ImageHeader />
        <Content a11y={ a11y } ref={ contentRef }>
          { children }
          <ScrollButtons contentRef={ contentRef } />
        </Content>
        <AccessibleIcon active={ a11y ? 'true' : undefined } onClick={ () => setA11y(s => !s) } />
        <SearchBox />
        <TopicNavigation />
        <FooterNavigation />
      </Container>
    </ThemeProvider>
  );
}
