import { createSelector } from '@reduxjs/toolkit';
import { intersection } from 'lodash';

import { dbBaseSelectors } from '../modules/db';
import { RootState } from '../store';
import { selectFilterFirstLetter } from './selectFilterFirstLetter';
import { selectFilterSearchQuery } from './selectFilterSearchQuery';
import { selectImplicitFilterCategoryIds } from './selectImplicitFilterCategoryIds';
import { selectSettings } from './selectSettings';

export const selectExhibitorsByFloorId = createSelector(
  dbBaseSelectors.exhibitor.selectAll,
  selectSettings,
  selectImplicitFilterCategoryIds,
  selectFilterFirstLetter,
  selectFilterSearchQuery,
  (_r: RootState, floorId: string) => floorId,
  (_r: RootState, _f: string, ideenwelt: boolean) => ideenwelt,
  (
    exhibitors,
    settings,
    filterCategoryIds,
    filterFirstLetter,
    filterSearchQuery,
    floorId,
    ideenwelt,
  ) => exhibitors
    .filter(({ floor, categories, name, keywords }) => {
      const catIds = categories?.map(c => c.category_id);
      if (!!filterCategoryIds.length && !intersection(filterCategoryIds, catIds).length) return false;
      if (!!filterFirstLetter && !name.toLocaleLowerCase().startsWith(filterFirstLetter)) return false;

      if (filterSearchQuery) {
        const hasKeywordMatch = !!keywords?.find(keyword => keyword.toLocaleLowerCase().includes(filterSearchQuery.toLocaleLowerCase()));
        const hasNameMatch = !!name.toLocaleLowerCase().includes(filterSearchQuery.toLocaleLowerCase());
        if (!hasKeywordMatch && !hasNameMatch) return false;
      }

      return floor === floorId || (
        ideenwelt &&
      floorId === settings?.ideenwelt_main_floor &&
      floor === settings?.ideenwelt_floor
      );
    })
    .sort((a, b) => a?.name?.toLocaleLowerCase().localeCompare(b?.name?.toLocaleLowerCase())),
);
