import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../../app/redux/modules/db';

export const selectBrandsByExhibitorId = createSelector(
  dbBaseSelectors.exhibitor.selectById,
  dbBaseSelectors.brand.selectEntities,
  (exhibitor, brands) => {
    return exhibitor?.brands
      ?.map(b => brands[b.brand_id])
      .sort((a, b) => a?.name?.toLocaleLowerCase().localeCompare(b?.name?.toLocaleLowerCase() || '') || 0);
  },
);
