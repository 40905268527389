import { Directus, QueryMany, TypeOf } from '@directus/sdk';
import {
  createAsyncThunk, createEntityAdapter,
  createSlice, EntitySelectors, EntityState, isRejected,
} from '@reduxjs/toolkit';

import { fetchCollectionData, fetchSingletonData } from '../../../api/fetch-collection-data';
import env from '../../../env';
import {
  CollectionNames, DbCollections, DirectusFile, DirectusFilePartial,
  mapCollections, SingletonCollections, SingletonNames,
} from '../../types';
import { RootState } from '../store';

type CollectionTypes = TypeOf<DbCollections, keyof DbCollections>;

interface FetchParams {
  collection: CollectionNames;
  query?: QueryMany<DbCollections[CollectionNames]>;
}

export const fetchData = createAsyncThunk('db', ({ collection, query }: FetchParams) => fetchCollectionData(collection, query));
export const fetchSingleton = createAsyncThunk('db-singleton', (collection: SingletonNames) => fetchSingletonData(collection));

export const fetchFiles = createAsyncThunk('directus_files/fetchAll', async () => {
  const directus = new Directus<{ directus_files: DirectusFile }>(env.REACT_APP_API_URL || '');
  const files = await directus?.items('directus_files').readMany({ limit: -1 });
  return files.data as DirectusFilePartial[] | undefined;
});

const dbAdapter = createEntityAdapter<CollectionTypes | DirectusFilePartial>();

type State = {
  [key in CollectionNames]: EntityState<DbCollections[key]>;
} & {
  [key in SingletonNames]: TypeOf<SingletonCollections, key>;
} & {
  files: EntityState<DirectusFilePartial>;
};

export const initialState = ({
  ...mapCollections(() => dbAdapter.getInitialState()),
  files: dbAdapter.getInitialState(),
}) as State;

export const dbSlice = createSlice({
  name: 'db',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      if (!action.payload) return;
      dbAdapter.upsertMany(state.files, action.payload);
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      const collection = action.meta.arg.collection;
      dbAdapter.upsertMany(state[collection], action.payload as DbCollections[typeof collection][]);
    });
    builder.addCase(fetchSingleton.fulfilled, (state, action) => {
      const collection = action.meta.arg;
      state[collection] = action.payload as SingletonCollections[typeof collection];
    });
    builder.addMatcher(isRejected(fetchData, fetchSingleton, fetchFiles), (_state, action) => {
      console.log('error', action.error);
    });
  },
});

export default dbSlice.reducer;

type Selectors = {
  [key in CollectionNames]: EntitySelectors<DbCollections[key], RootState>;
} & {
  files: EntitySelectors<DirectusFilePartial, RootState>;
};

export const dbBaseSelectors = {
  ...mapCollections(name => dbAdapter.getSelectors(state => state.db[name])),
  files: dbAdapter.getSelectors((state: RootState) => state.db.files),
} as Selectors;
