import { cloneDeep } from 'lodash';
import { createContext, ReactNode, useCallback, useEffect, useRef } from 'react';

import useClearFilters from '../../hooks/useClearFilters';
import { restoreAppState } from '../../redux/modules/app';
import { selectAppSlice } from '../../redux/selectors/selectAppSlice';
import { RootState, useAppDispatch, useAppSelector, useStatefulLocation } from '../../redux/store';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const HistoryTrackerContext = createContext(() => {});

interface Props {
  children: ReactNode;
}

export default function HistoryTracker({ children }: Props) {
  const app = useAppSelector(selectAppSlice);
  const loc = useStatefulLocation();
  const dispatch = useAppDispatch();
  const clearFilters = useClearFilters();

  const stateMapRef = useRef<Record<string, { location: typeof loc, app: RootState['app'] }>>({});
  const locationRef = useRef<typeof loc>(loc);

  // store app state for history entry
  useEffect(() => {
    // console.log(locationRef.current.key, '==', loc.key, '?', locationRef.current.key === loc.key, { app });
    if (app && loc.key && locationRef.current.key === loc.key) {
      stateMapRef.current[loc.key] = { location: cloneDeep(loc), app: cloneDeep(app) };
      // console.log('in:', loc.pathname, app.filterSearchQuery);
    }
    locationRef.current = loc;
  }, [loc, app, stateMapRef, locationRef]);

  // restore or clear app state after navigation
  useEffect(() => {
    if (loc.key && stateMapRef.current[loc.key]?.app) {
      // console.log('out:', stateMapRef.current[loc.key]?.app.filterSearchQuery);
      dispatch(restoreAppState({ ...stateMapRef.current[loc.key]?.app }));
    }
    else clearFilters(loc.state);
  }, [loc]);

  const clearHistory = useCallback(() => stateMapRef.current = {}, []);

  return (<HistoryTrackerContext.Provider value={ clearHistory }>{ children }</HistoryTrackerContext.Provider>);
}
