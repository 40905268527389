
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppSelector } from '../../app/redux/store';
import ItemTile from '../../app/view/components/ItemTile';

interface Props {
  exhibitorId: string;
}

export default function ExhibitorTile({ exhibitorId }: Props) {
  const exhibitor = useAppSelector(state => dbBaseSelectors.exhibitor.selectById(state, exhibitorId));

  if (!exhibitor) return null;
  return <ItemTile to={ `/exhibitors/${exhibitorId}` } image={ exhibitor?.logo_image } name={ `EXHIBITOR: ${exhibitor.name}` } />;
}
