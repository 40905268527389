import styled from 'styled-components';

import { selectIdeenweltFloor } from '../../app/redux/selectors/selectIdeenweltFloor';
import { useAppSelector } from '../../app/redux/store';
import BoothAreasOverlay from '../../app/view/components/BoothAreasOverlay';
import DirectusImage from '../directus/DirectusImage';

const FlexContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
`;

const Container = styled.div`
  position: relative;
  width: 911px;
  margin: 0 auto;

  > img {
    width: 911px;
    object-fit: contain;
  }
`;

export default function IdeenweltMap() {
  const floor = useAppSelector(selectIdeenweltFloor);

  return (
    <FlexContainer>
      <Container>
        <DirectusImage asset={ floor?.map } width={ 864 } />
        <BoothAreasOverlay />
      </Container>
    </FlexContainer>
  );
}
