import { useParams, useRouteMatch } from 'react-router-dom';

import { useAppSelector } from '../../../../redux/store';
import { IDEENWELT_ROUTE } from '../../../../types';
import { selectDefaultHeaderImage } from './selectDefaultHeaderImage';
import { selectExhibitorHeaderImage } from './selectExhibitorHeaderImage';
import { selectIdeenweltHeaderImage } from './selectIdeenweltHeaderImage';
import { selectRootCategoryHeaderImage } from './selectRootCategoryHeaderImage';

export default function useHeaderImageId() {
  const { exhibitorId } = useParams<{ exhibitorId: string }>();
  const isExhibitorPage = !!useRouteMatch('/exhibitors/:exhibitorId');
  const isIdeenwelt = !!useRouteMatch(IDEENWELT_ROUTE);
  const defaultHeaderImage = useAppSelector(selectDefaultHeaderImage);
  const ideenweltHeaderImage = useAppSelector(selectIdeenweltHeaderImage);
  const rootCategoryHeaderImage = useAppSelector(selectRootCategoryHeaderImage);
  const exhibitorHeaderImage = useAppSelector(state => selectExhibitorHeaderImage(state, exhibitorId));

  if (isIdeenwelt && ideenweltHeaderImage) return ideenweltHeaderImage;
  if (isExhibitorPage && exhibitorHeaderImage) return exhibitorHeaderImage;
  if (rootCategoryHeaderImage) return rootCategoryHeaderImage;
  return defaultHeaderImage;
}
