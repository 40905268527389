import { Route, Switch } from 'react-router-dom';

import BrandDetailsPage from '../../../modules/BrandDetailsPage';
import BrandsPage from '../../../modules/BrandsPage';
import ExhibitorDetailsPage from '../../../modules/ExhibitorDetailsPage';
import ExhibitorsPage from '../../../modules/ExhibitorsPage';
import FloorsOverviewPage from '../../../modules/FloorsOverviewPage';
import HomePage from '../../../modules/HomePage';
import IdeenweltPage from '../../../modules/IdeenweltPage';
import useIdleTimeoutRedirect from '../../hooks/useIdleTimeoutRedirect';
import useLoadDbData from '../../hooks/useLoadDbData';
import { EXHIBITOR_ROUTE, EXHIBITORS_ROUTE, IDEENWELT_ROUTE } from '../../types';
import HistoryTracker from '../components/HistoryTracker';
import Layout from '../components/Layout';
import useLoadDisplayLocation from './useLoadDisplayLocation';

function App() {
  useLoadDbData();
  useIdleTimeoutRedirect();
  useLoadDisplayLocation();

  return (
    <HistoryTracker>
      <Switch>
        <Route path="/brands/:brandId">
          <Layout>
            <BrandDetailsPage />
          </Layout>
        </Route>
        <Route path="/brands">
          <Layout>
            <BrandsPage />
          </Layout>
        </Route>
        <Route path="/floors">
          <Layout>
            <FloorsOverviewPage />
          </Layout>
        </Route>
        <Route path={ EXHIBITOR_ROUTE }>
          <Layout>
            <ExhibitorDetailsPage />
          </Layout>
        </Route>
        <Route path={ EXHIBITORS_ROUTE }>
          <Layout>
            <ExhibitorsPage />
          </Layout>
        </Route>
        <Route path="/search">
          <Layout>
            <ExhibitorsPage />
          </Layout>
        </Route>
        <Route path={ IDEENWELT_ROUTE }>
          <Layout>
            <IdeenweltPage />
          </Layout>
        </Route>
        <Route path="/">
          <Layout>
            <HomePage />
          </Layout>
        </Route>
      </Switch>
    </HistoryTracker>
  );
}

export default App;
