import 'react-simple-keyboard/build/css/index.css';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setFilterSearchQuery } from '../../../redux/modules/app';
import { selectFilterSearchQuery } from '../../../redux/selectors/selectFilterSearchQuery';
import { selectShowSearchBox } from '../../../redux/selectors/selectShowSearchBox';
import { useAppSelector, useStatefulHistory } from '../../../redux/store';
import CategoryFilter from './CategoryFilter';
import Keyboard from './Keyboard';
import { ReactComponent as TopicDown } from './topic-down.svg';
import { ReactComponent as TopicUp } from './topic-up.svg';

const Container = styled.div`
	position: relative;
`;

const CollapseButton = styled.button`
	position: absolute;
	z-index: 10;
	right: 1.5rem;
	top: 20px;
	border: none;
  background: none;
`;

export default function SearchBox() {
  const showSearchBox = useAppSelector(selectShowSearchBox);
  const query = useAppSelector(selectFilterSearchQuery);
  const history = useStatefulHistory();

  const dispatch = useDispatch();

  const [tempQuery, setTempQuery] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onCollapseClick = useCallback(
    () => setIsCollapsed(col => !col),
    [setIsCollapsed],
  );

  const onSearch = useCallback(value => {
    if (history.location.pathname !== '/search') history.push('/search', { showSearchBox: true, filterSearchQuery: value });
    dispatch(setFilterSearchQuery(value));
  }, [dispatch]);

  useEffect(() => setTempQuery(query), [query]);

  if (!showSearchBox) return null;

  return (
    <Container>
      <CollapseButton onClick={ onCollapseClick }>
        { isCollapsed ? <TopicUp/> : <TopicDown/> }
      </CollapseButton>

      <CategoryFilter />

      { !isCollapsed && (
        <Keyboard value={ tempQuery } onSearch={ onSearch } onChange={ q => setTempQuery(q) } />
      ) }
    </Container>
  );
}
