import { createSelector } from '@reduxjs/toolkit';

import { selectCurrentFloorId } from '../../app/redux/selectors/selectCurrentFloorId';
import { selectSettings } from '../../app/redux/selectors/selectSettings';

export const selectIsIdeenweltBaseFloorSelected = createSelector(
  selectSettings,
  selectCurrentFloorId,
  (settings, selectedFloor) => settings?.ideenwelt_main_floor === selectedFloor,
);
