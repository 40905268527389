import { createContext, PropsWithChildren, useMemo } from 'react';
import { io, Socket } from 'socket.io-client';
import { ManagerOptions } from 'socket.io-client/build/esm/manager';
import { SocketOptions } from 'socket.io-client/build/esm/socket';

import env from '../../env';

interface Props {
  options?: Partial<ManagerOptions & SocketOptions>;
}

interface WebsocketContextType {
  websocketUrl: string;
  websocketClient: Socket;
}

export interface WebsocketWebhookPayload {
  event: string;
  accountability: Record<string, unknown>;
  payload: Record<string, unknown>;
  keys: string[];
  collection: string;
}

export const WebsocketContext = createContext<WebsocketContextType | undefined>(undefined);

export default function WebsocketProvider({ children, options }: PropsWithChildren<Props>) {
  if (!env.REACT_APP_WEBHOOK_SIDECAR_WEBSOCKET_URL) {
    throw new Error('Websocket Websocket Url missing. Please provide via `REACT_APP_WEBHOOK_SIDECAR_WEBSOCKET_URL` env variable');
  }

  const websocketUrl = env.REACT_APP_WEBHOOK_SIDECAR_WEBSOCKET_URL || '';
  const websocketClient = io(websocketUrl, options);

  const value = useMemo<WebsocketContextType>(() => ({
    websocketUrl,
    websocketClient,
  }), [websocketUrl, websocketClient]);

  return <WebsocketContext.Provider value={ value }>{ children }</WebsocketContext.Provider>;
}
