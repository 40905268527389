import { useMemo } from 'react';
import styled from 'styled-components';

import { dbBaseSelectors } from '../../app/redux/modules/db';
import { selectExhibitorsByFloorId } from '../../app/redux/selectors/selectExhibitorsByFloorId';
import { useAppSelector } from '../../app/redux/store';
import { Exhibitor, Floor } from '../../app/types';
import Heading from '../../app/view/components/Heading';
import ExhibitorTile from '../ExhibitorsPage/ExhibitorTile';

interface Props {
  floorId: Floor['id'];
  idList?: Exhibitor['id'][];
}

const Container = styled.div`
  width: auto;
  padding-bottom: 30px;
`;

const Exhibitors = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  position: relative;
`;

export default function ExhibitorsList({ floorId, idList }: Props) {
  const floorName = useAppSelector(state => dbBaseSelectors.floor.selectById(state, floorId)?.name);
  const exhibitors = useAppSelector(state => selectExhibitorsByFloorId(state, floorId, true));

  const displayedExhibitors = useMemo(
    () => {
      if (idList) {
        return exhibitors.filter(e => idList.includes(e.id));
      }

      return exhibitors;
    },
    [exhibitors, idList],
  );

  if (!displayedExhibitors.length) return null;

  return (
    <Container>
      <Heading level={ 2 }>{ floorName }</Heading>
      <Exhibitors>
        {
          displayedExhibitors?.map(({ id }) => (<ExhibitorTile key={ id } exhibitorId={ id } />))
        }
      </Exhibitors>
    </Container>
  );
}
