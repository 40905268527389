import { useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory, useLocation } from 'react-router-dom';

import { WebsocketContext, WebsocketWebhookPayload } from '../../modules/directus/WebsocketProvider';
import { doCacheRefresh } from '../redux/modules/app';
import { selectDoCacheRefresh } from '../redux/selectors/selectDoCacheRefresh';
import { selectSettings } from '../redux/selectors/selectSettings';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { HistoryTrackerContext } from '../view/components/HistoryTracker';
import useClearFilters from './useClearFilters';

export default function useIdleTimeoutRedirect() {
  const loc = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const clearFilters = useClearFilters();
  const clearHistory = useContext(HistoryTrackerContext);
  const timeout = useAppSelector(selectSettings)?.idle_timeout;
  const pendingCacheRefresh = useAppSelector(selectDoCacheRefresh);

  const websocketClient = useContext(WebsocketContext)?.websocketClient;

  useEffect(() => {
    if (websocketClient) {
      websocketClient.on('/api/directus/webhook', (data: WebsocketWebhookPayload) => {
        if (
          data.event === 'items.create' ||
          data.event === 'items.update' ||
          data.event === 'items.delete'
        ) {
          dispatch(doCacheRefresh(true));
        }
      });
    }

    return () => {
      websocketClient?.removeAllListeners();
    };
  }, [websocketClient]);

  useIdleTimer({
    startOnMount: true,
    timeout: (timeout || 10) * 1000,
    onIdle: () => {
      clearFilters();
      clearHistory();

      if (pendingCacheRefresh) {
        window.location.href = '/';
      }
      else {
        if (loc.pathname === '/') return;
        history.replace('/');
      }
    },
  });
}
