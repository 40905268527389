import { useCallback } from 'react';

import { setFilterCategoryIds, setFilterFirstLetter, setFilterSearchQuery, setFloor, showSearchBox } from '../redux/modules/app';
import { selectCurrentPosition } from '../redux/selectors/selectCurrentPosition';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';

export default function useClearFilters () {
  const dispatch = useAppDispatch();
  const floorId = useAppSelector(selectCurrentPosition)?.floor;

  return useCallback((app?: Partial<RootState['app']>) => {
    dispatch(setFilterFirstLetter(app?.filterFirstLetter || ''));
    dispatch(setFilterCategoryIds(app?.filterCategoryIds || []));
    dispatch(setFilterSearchQuery(app?.filterSearchQuery || ''));
    dispatch(showSearchBox(!!app?.showSearchBox || false));
    if (floorId) dispatch(setFloor(floorId));
  }, [ dispatch, floorId ]);

}
