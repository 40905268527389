import { isEqual } from 'lodash';
import { useRef } from 'react';

export default function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>();

  if (!isEqual(value, ref.current)) ref.current = value;

  return ref.current;
}
