import { memo, useContext, useMemo } from 'react';

import useDeepCompareMemoize from '../../app/hooks/useDeepCompareMemoize';
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppSelector } from '../../app/redux/store';
import { DirectusContext } from './DirectusProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DirectusAsset = string | { id: string } & Record<string, any>;

interface Props {
  /** The asset as `string` or `object` with an `id` property of type `string`. */
  asset: string | undefined,
  // Optional title. If not set, the title is fetched from the cms asset
  title?: string | null | undefined,
  /** The width of the thumbnail in pixels. */
  width?: number,
  /** The height of the thumbnail in pixels. */
  height?: number,
  /** The quality of the thumbnail (1 to 100). */
  quality?: number,
  /** The fit of the thumbnail while always preserving the aspect ratio. */
  fit?: 'cover' | 'contain' | 'inside' | 'outside',
  // Obtional fallback src, in case no image is found
  fallbackSrc?: string;
  className?: string;
}

/* eslint-disable-next-line max-len */
export const defaultFallbackSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

interface ImageProps {
  className?: string;
  src: string;
  alt: string;
}
const Image = memo(function Img({ className, src, alt }: ImageProps) {
  return <img className={ className } src={ src } alt={ alt } />;
});

export default function DirectusImage({ asset: assetId = '', title, fallbackSrc, className, ...params }: Props) {
  const file = useAppSelector(state => dbBaseSelectors.files.selectById(state, assetId));
  const assetTitle = title || file?.title || '';

  const apiUrl = useContext(DirectusContext)?.apiUrl;

  // set default value for quality to 100%
  if (!params.quality) params.quality = 100;

  const url = useMemo(() => {
    if (!file) return fallbackSrc || '';

    const searchParams = (new URLSearchParams(params as Record<string, string>)).toString();
    return `${ apiUrl }/assets/${ assetId }?${ searchParams }`;
  }, [apiUrl, assetId, file, useDeepCompareMemoize(params)]); // make sure to avoid rerender on changed params

  return <Image className={ className } src={ url } alt={ assetTitle } />;
}
