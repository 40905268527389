import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { selectCurrentHomeMap } from '../../app/redux/selectors/selectCurrentHomeMap';
import { useAppSelector } from '../../app/redux/store';
import { FLOOR, IDEENWELT_ROUTE } from '../../app/types';
import BoothAreasOverlay from '../../app/view/components/BoothAreasOverlay';
import DirectusImage from '../directus/DirectusImage';
import { selectIsIdeenweltBaseFloorSelected } from './selectIsIdeenweltBaseFloorSelected';

const Container = styled.div`
  position: relative;
  width: ${FLOOR.W}px;
  height: ${FLOOR.H}px;

  > img {
    width: ${FLOOR.W}px;
    height: ${FLOOR.H}px;
    object-fit: contain;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  width: ${FLOOR.W}px;
  height: ${FLOOR.H}px;
  top: 0;
`;

const Ideenwelt = styled(Link)`
  position: absolute;
  display: block;
  top: 7px;
  width: 200px;
  height: 200px;
  z-index: 100;
  /* background-color: ${({ theme }) => theme.palette.primary.main}; */
`;

const Ideenwelt1 = styled(Ideenwelt)`
  width: 238px;
  height: 306px;
  left: 126px;
`;
const Ideenwelt2 = styled(Ideenwelt)`
  width: 136px;
  height: 189px;
  left: 364px;
`;
const Ideenwelt3 = styled(Ideenwelt)`
  width: 169px;
  height: 563px;
  left: 499px;
`;
const Ideenwelt4 = styled(Ideenwelt)`
  top: 68px;
  width: 108px;
  height: 255px;
  left: 668px;
`;
const Ideenwelt5 = styled(Ideenwelt)`
  top: 407px;
  width: 14px;
  height: 163px;
  left: 668px;
`;
const Ideenwelt6 = styled(Ideenwelt)`
  top: 407px;
  width: 94px;
  height: 213px;
  left: 682px;
`;

export default function HomeMap() {
  const map = useAppSelector(selectCurrentHomeMap);
  const isIdeenweltBaseFloorSelected = useAppSelector(selectIsIdeenweltBaseFloorSelected);

  return (
    <Container>
      <DirectusImage asset={ map } width={ FLOOR.W } height={ FLOOR.H } />
      <BoothAreasOverlay />
      { isIdeenweltBaseFloorSelected &&
        <OverlayContainer>
          <Ideenwelt1 to={ IDEENWELT_ROUTE } />
          <Ideenwelt2 to={ IDEENWELT_ROUTE } />
          <Ideenwelt3 to={ IDEENWELT_ROUTE } />
          <Ideenwelt4 to={ IDEENWELT_ROUTE } />
          <Ideenwelt5 to={ IDEENWELT_ROUTE } />
          <Ideenwelt6 to={ IDEENWELT_ROUTE } />
        </OverlayContainer>
      }
    </Container>
  );
}
