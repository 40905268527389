import styled from 'styled-components';

import DirectusImage, { defaultFallbackSrc } from '../../../../../modules/directus/DirectusImage';
import useHeaderImageId from './useHeaderImageId';

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 318px;

  > img {
    width: 100vw;
    height: 318px;
    object-fit: cover;
  }
`;

export default function ImageHeader() {
  const imageId = useHeaderImageId();

  return (
    <Container>
      <DirectusImage
        width={ 1080 }
        asset={ imageId }
        fallbackSrc={ defaultFallbackSrc }
      />
    </Container>
  );
}
