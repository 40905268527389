import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../modules/db';
import { selectCurrentFloorId } from './selectCurrentFloorId';
import { selectCurrentPosition } from './selectCurrentPosition';

export const selectCurrentHomeMap = createSelector(
  dbBaseSelectors.floor.selectEntities,
  selectCurrentPosition,
  selectCurrentFloorId,
  (floors, position, selectedFloor) => {
    if (position?.floor === selectedFloor) return position?.map;
    return floors[selectedFloor]?.map;
  },
);
