import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { showSearchBox } from '../../app/redux/modules/app';
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppDispatch, useAppSelector } from '../../app/redux/store';
import Heading from '../../app/view/components/Heading';
import BrandTile from '../BrandsPage/BrandTile';
import DirectusImage, { defaultFallbackSrc } from '../directus/DirectusImage';
import { selectBrandsByExhibitorId } from './selectBrandsByExhibitorId';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  font-size: 1.6rem;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-top: 66px;
  margin-bottom: 30px;
`;

const MapImage = styled(DirectusImage)`
  width: 518px;
  margin: 0;
`;

const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 308px;
`;

const ExhibitorLogoTop = styled(DirectusImage)`
  width: 308px;
  height: 128px;
  margin: 0 0 60px;
  border: 1px solid #bab9b9;
  object-fit: contain;
`;

const ExhibitorLogoBottom = styled(ExhibitorLogoTop)`
  margin-bottom: 10px;
  width: 308px;
  height: 128px;
`;

const SideHeading = styled(Heading)`
  margin: 0 0 1.5rem 0;
  white-space: nowrap;
`;

const BrandsContainer = styled.div<{ large: boolean }>`
  overflow-y: hidden;
`;

const SideBrandTile = styled(BrandTile)`
  height: 100px;
  margin-bottom: 29.5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const WebsiteContainer = styled.div`
  position: fixed;
  bottom: 310px;
  right: 85px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.common.grayText};
  padding: 10px 0 5px 10px;
`;

const ContentWrapper = styled.div`
  max-height: 335px;
  overflow-y: auto;
`;

const ContentHeadline = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`;

const Markdown = styled(ReactMarkdown)<{ isNarrow: boolean }>`
  padding-right: ${ ({ isNarrow }) => isNarrow ? '320px' : '0' };

  ul {
    padding: 0 0 0 1rem;
  }

  li {
    list-style-type: '- ';
    list-style-position: outside;
  }
`;

export default function ExhibitorDetailsPage() {
  const dispatch = useAppDispatch();
  const { exhibitorId } = useParams<{ exhibitorId: string }>();
  const exhibitor = useAppSelector(state => dbBaseSelectors.exhibitor.selectById(state, exhibitorId));
  const brands = useAppSelector(state => selectBrandsByExhibitorId(state, exhibitorId));

  useEffect(() => {
    dispatch(showSearchBox(false));
  }, []);

  return (
    <PageContainer>
      <TopContainer>
        <MapImage
          asset={ exhibitor?.map_image }
          fallbackSrc={ defaultFallbackSrc }
        />
        <SidebarContainer>
          { exhibitor?.layout !== 'many_brands' &&
              <ExhibitorLogoTop
                asset={ exhibitor?.logo_image }
                fallbackSrc={ defaultFallbackSrc }
              />
          }
          { exhibitor?.layout !== 'no_brands' && !!exhibitor?.brands?.length &&
            <>
              <SideHeading level={ 4 }>Verfügbare Marken</SideHeading>
              <BrandsContainer large={ exhibitor?.layout === 'many_brands' }>
                {
                  brands?.map((brand) => brand ? (<SideBrandTile disabled={ true } key={ brand.id } id={ brand.id } />) : null)
                }
              </BrandsContainer>
            </>
          }
        </SidebarContainer>
      </TopContainer>
      <Heading level={ 2 } marginTop="0" marginBottom="0">{ exhibitor?.name }</Heading>
      <ContentWrapper>
        { exhibitor?.headline && <ContentHeadline>{ exhibitor?.headline }</ContentHeadline> }
        <Markdown isNarrow={ exhibitor?.layout === 'many_brands' } className="test">
          { exhibitor?.description || '' }
        </Markdown>
      </ContentWrapper>
      <WebsiteContainer>
        { exhibitor?.layout === 'many_brands' &&
          <ExhibitorLogoBottom
            asset={ exhibitor?.logo_image }
            fallbackSrc={ defaultFallbackSrc }
          />
        }
        <span>{ exhibitor?.website }</span>
      </WebsiteContainer>
    </PageContainer>
  );
}
