import { PartialItem } from '@directus/sdk';

import { mapObject } from '../modules/utils';
import { CollectionItem } from './redux/types';

export const IDEENWELT_ROUTE = '/ideenwelt';
export const EXHIBITORS_ROUTE = '/exhibitors';
export const EXHIBITOR_ROUTE = `${EXHIBITORS_ROUTE}/:exhibitorId`;

export interface ImageDimensions {
  W: number;
  H: number;
}

export const FLOOR: ImageDimensions = { W: 769, H: 1203 };
export const IDEENWELT: ImageDimensions = { W: 911, H: 753 };

export interface Floor extends CollectionItem {
  name: string;
  map: string;
  width: number;
  description: string;
}

export interface Exhibitor extends CollectionItem {
  name: string;
  floor: Floor['id'];
  brands?: { brand_id: Brand['id'] }[];
  categories?: { category_id: Category['id'] }[];
  keywords?: string[];
  area?: Area['id'];
  areas?: Area['id'][];
  website?: string;
  headline?: string;
  description?: string;
  banner_image?: string;
  logo_image?: string;
  map_image?: string;
  layout: 'less_brands' | 'no_brands' | 'many_brands';
  displayed_as_brand?: boolean;
}

export interface Brand extends CollectionItem {
  name: string;
  keywords?: string;
  exhibitors?: { exhibitor_id: Exhibitor['id'] }[];
  image: string;
}

// TODO: improve type guard to better detect brand type
export function isBrand(obj: unknown): obj is Brand {
  const brand = obj as Brand;
  if (!brand.name) return false;
  if (brand.image === undefined) return false;
  return true;
}

export interface Category extends CollectionItem {
  name: string;
  keywords?: string;
  parent: Category['id'] | null;
  exhibitors?: { exhibitor_id: Exhibitor['id'] }[];
  header_image?: string;
}

export interface Position extends CollectionItem {
  map: string;
  name: string;
  floor: Floor['id'];
}

export interface Area extends CollectionItem {
  name: string;
  coordinate_x: number;
  coordinate_y: number;
  width: number;
  height: number;
  exhibitor: Exhibitor['id'];
}

export interface DirectusFile {
  charset?: string | null;
  description?: string | null;
  duration?: string | null;
  embed?: string | null;
  filename_disk: string;
  filename_download: string;
  filesize: string;
  folder: string | null;
  height?: number;
  id: string;
  location: string | null;
  metadata: string | null;
  modified_by: string | null;
  modified_on: string | null;
  storage: string | null;
  tags: string | null;
  title: string | null;
  type: string | null;
  uploaded_by: string | null;
  uploaded_on: string | null;
  width?: number
}

export type DirectusFilePartial = PartialItem<DirectusFile>;

export interface Settings {
  id: number;
  ideenwelt_floor: Floor['id'];
  ideenwelt_main_floor: Floor['id'];
  page_title_prefix: string;
  display_overlays: boolean;
  default_header_image?: string;
  ideenwelt_header_image?: string;
  idle_timeout?: number;
}

const dbCollections = {
  brand: {} as Brand,
  exhibitor: {} as Exhibitor,
  floor: {} as Floor,
  category: {} as Category,
  position: {} as Position,
  area: {} as Area,
};

export type DbCollections = typeof dbCollections;
export type CollectionNames = keyof DbCollections;

const singletonCollections = {
  settings: {} as Settings,
};

export type SingletonCollections = typeof singletonCollections;
export type SingletonNames = keyof SingletonCollections;

export const collectionNames = Object.keys(dbCollections) as CollectionNames[];

export function mapCollections<T>(map: (collectionName: CollectionNames) => T) {
  return mapObject((_, name) => map(name), dbCollections);
}
