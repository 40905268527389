
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppSelector } from '../../app/redux/store';
import ItemTile from '../../app/view/components/ItemTile';

interface Props {
  id: string;
  disabled?: boolean;
  className?: string;
}

export default function BrandTile({ id, disabled, className }: Props) {
  const brand = useAppSelector(state => dbBaseSelectors.brand.selectById(state, id));

  if (!brand) return null;

  return <ItemTile
    disabled={ disabled }
    className={ className }
    to={ `/brands/${brand.id}` }
    image={ brand.image }
    name={ `BRAND: ${brand.name}` }
  />;
}
