import 'react-simple-keyboard/build/css/index.css';
import './keyboard.scss';

import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactKeyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #585858;
`;

const InputContainer = styled.div`
  padding: 1rem 12.35rem 0 12.35rem;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  flex: 1;
  background-color: #434343;
  border: 1px solid #8B8B8B;
  height: 56px;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;
  border-radius: 5px;
  color: #efefef;
`;

const KeyboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 6rem 1rem 6rem;
`;

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
}

const layout = {
  default: [
    'Q W E R T Z U I O P Ü',
    'A S D F G H J K L Ö Ä',
    'Y X C V B N M {backspace}',
    '{space} {enter}',
  ],
};

const display = {
  default: {
    '{space}': ' ',
    '{enter}': 'SUCHEN <EnterIcon>&#8629;</EnterIcon>',
    '{backspace}': '&#9003;',
  },
};

function Keyboard({ onChange, onSearch, value }: Props) {
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [inputValue, setInputValue] = useState(value || '');

  const onKeyboardChange = useCallback((input: string) => {
    setInputValue(input);
    onChange?.(input);
  }, [onChange]);

  const onKeyPress = useCallback((button: string) => {
    if (button === '{enter}') {
      onChange?.(inputValue || '');
      onSearch?.(inputValue || '');
    }
  }, [onChange, onSearch, inputValue]);

  useEffect(() => {
    if (!keyboardRef.current) return;

    // clear keyboard input when value is cleared outside of the component
    if (!value && keyboardRef.current.getInput() !== value) {
      keyboardRef.current.clearInput();
    }

    if (value) {
      keyboardRef.current.setInput(value);
      setInputValue(value);
    }
  }, [value, keyboardRef]);

  return (
    <Container>
      <InputContainer>
        <StyledInput placeholder="Was suchen Sie?" value={ inputValue } disabled={ true } />
      </InputContainer>

      <KeyboardContainer>
        <ReactKeyboard
          keyboardRef={ ref => keyboardRef.current = ref }
          onChange={ onKeyboardChange }
          onKeyPress={ onKeyPress }
          layout={ layout }
          theme={ 'hg-theme-default' }
          display={ display.default }
          mergeDisplay
        />
      </KeyboardContainer>
    </Container>
  );
}

export default memo(Keyboard);
