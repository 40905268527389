import { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  level?: number;
  marginTop?: string;
  marginBottom?: string;
  className?: string;
}

interface HeadingProps {
  marginTop?: string;
  marginBottom?: string;
}

const defaultMargin = '32px';

const H1 = styled('h1')<HeadingProps>`
  font-size: 3rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
  margin-top: ${({ marginTop }) => marginTop ? marginTop : defaultMargin };
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : defaultMargin };
`;

const H2 = styled('h2')<HeadingProps>`
  font-size: 2.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
  margin-top: ${({ marginTop }) => marginTop ? marginTop : defaultMargin };
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : defaultMargin };
`;

const H3 = styled('h3')<HeadingProps>`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
  margin-top: ${({ marginTop }) => marginTop ? marginTop : defaultMargin };
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : defaultMargin };
`;

const H4 = styled('h4')<HeadingProps>`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  margin-top: ${({ marginTop }) => marginTop ? marginTop : defaultMargin };
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : defaultMargin };
`;

const H5 = styled('h5')<HeadingProps>`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  margin-top: ${({ marginTop }) => marginTop ? marginTop : defaultMargin };
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : defaultMargin };
`;

const H6 = styled('h6')<HeadingProps>`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  margin-top: ${({ marginTop }) => marginTop ? marginTop : defaultMargin };
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : defaultMargin };
`;

function Heading({ children, level = 1, marginTop, marginBottom, className }: PropsWithChildren<Props>) {
  switch (level) {
    case 1: return <H1 className={ className } marginTop={ marginTop } marginBottom={ marginBottom }>{ children }</H1>;
    case 2: return <H2 className={ className } marginTop={ marginTop } marginBottom={ marginBottom }>{ children }</H2>;
    case 3: return <H3 className={ className } marginTop={ marginTop } marginBottom={ marginBottom }>{ children }</H3>;
    case 4: return <H4 className={ className } marginTop={ marginTop } marginBottom={ marginBottom }>{ children }</H4>;
    case 5: return <H5 className={ className } marginTop={ marginTop } marginBottom={ marginBottom }>{ children }</H5>;
    default: return <H6 className={ className } marginTop={ marginTop } marginBottom={ marginBottom }>{ children }</H6>;
  }
}

export default memo(Heading);
