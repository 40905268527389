import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../../app/redux/modules/db';
import { selectFilteredExhibitors } from '../../app/redux/selectors/selectFilteredExhibitors';
import { selectFilterFirstLetter } from '../../app/redux/selectors/selectFilterFirstLetter';
import { selectImplicitFilterCategoryIds } from '../../app/redux/selectors/selectImplicitFilterCategoryIds';
import { RootState } from '../../app/redux/store';
import { isString } from '../utils';

type FilterOptions = {
  disableFirstLetter?: boolean;
  disableCategory?: boolean;
} | undefined | null;

export const selectFilteredBrandsPageEntries = createSelector(
  dbBaseSelectors.brand.selectAll,
  dbBaseSelectors.exhibitor.selectEntities,
  selectFilteredExhibitors,
  selectFilterFirstLetter,
  selectImplicitFilterCategoryIds,
  (_: RootState, options: FilterOptions) => options,
  (brands, exhibitors, filteredExhibitors, filterFirstLetter, filterCategoryIds, options) => {
    const filteredBrands = brands
      .filter(({ name }) => (!!options?.disableFirstLetter || !filterFirstLetter)
        ? true
        : name.charAt(0).toLocaleLowerCase() === filterFirstLetter,
      )
      .filter(brand => (!!options?.disableCategory || !filterCategoryIds?.length)
        ? true
        : !!brand.exhibitors
          ?.map(({ exhibitor_id }) => exhibitors[exhibitor_id])
          .flatMap(exhibitor => exhibitor?.categories?.map(({ category_id }) => category_id))
          .filter(isString)
          .some(id => filterCategoryIds.includes(id)),
      );

    return [...filteredBrands, ...filteredExhibitors
      .filter(({ displayed_as_brand }) => !displayed_as_brand),
    ].sort((a, b) => a?.name?.toLocaleLowerCase().localeCompare(b?.name?.toLocaleLowerCase()));
  },
);
